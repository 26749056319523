/* styles.css */

#cover-spin {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}

#cover-spin-content {
        background-color: #475f87;
        display: block;
        width: 716px;
        color: white;
        position: absolute;
        left: 48%;
        top: 40%;
        padding: 70px; // Add padding to center the content
        border-radius: 10px; // Add rounded corners
        text-align: center; // Center the content inside the loading screen
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); // Add a subtle shadow for a 3D effect
}

.percentage-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}