.main_container {
  width: 100%;
  background: #F7F7F7 !important;
}

.custom-sidebar-menu {
  width: 196px;
  position: absolute;
  display: flex;
  z-index: 1;
  float: left;
  background: #2A3F54;
  padding: 0 0;
}

.right-col {
  margin-left: 196px !important;
  padding: 10px 20px 0;
  max-height: 100%;
  overflow: hidden;
}

.row {
  padding-top: 10px;
  margin-right: -10px;
  margin-left: -10px;
}


.ReactModal__Overlay {
  z-index: 1000;
}

.listing-pagination {
  max-width: unset !important;
}

.field-value {
  width: 100%;
  line-height: 38px;
  min-height: 38px;
  border: 1px solid #475F87;
  padding-left: 1rem;
  padding-right: 1rem;
}

.tab-default-style {
  padding-bottom: 30px;
  padding-top: 15px;
}

.tab-default-style .col-md-1,
.tab-default-style .col-md-2,
.tab-default-style .col-md-3,
.tab-default-style .col-md-4,
.tab-default-style .col-md-5,
.tab-default-style .col-md-6,
.tab-default-style .col-md-7,
.tab-default-style .col-md-8,
.tab-default-style .col-md-9,
.tab-default-style .col-md-10,
.tab-default-style .col-md-11,
.tab-default-style .col-md-12 {
  margin-bottom: 15px;
}

.rc-time-picker-input {
  min-height: 38px !important;
}


.non-billed,
.non-invoiced {
  background-color: lightblue !important;
}

.non-billed .listing-table-column,
.non-invoiced .listing-table-column {
  color: black !important;
}

.bordered {
  background-color: white;
  border: 1px black solid;
}

.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.middle-screen {
  margin: auto;
}

.centered {
  display: flex;
  text-align: center;
}

button.link {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  text-decoration: underline;
  cursor: pointer;
}

.text {
  color: black !important;
}

.remaining-amount-row {
  color: white !important;
  background-color: red !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 1;
  filter: invert(0);
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1
}