.sidebar-logo-link {
  height: 160px;
  text-align: center;
  display: block;
}

.sidebar-logo {
  margin-bottom: 17px;
  margin-top: 7px;
  width: 107px;
}

.sidebar-title {
  border: 0;
  margin-bottom: 0;
  width: 196px;
  float: left;
  border-radius: 0;
  height: 57px;
  position: relative;
  min-height: 50px;
}

.sidebar-menu {
  display: block;
  margin-bottom: 35px;
}

.active {
  border-right: 5px solid #1ABB9C;
  background: rgba(255, 255, 255, 0.05) !important;
}

.sidebar-menu-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  background: #2A3F54;
}

.sidebar-menu-list-item {
  position: relative;
  display: block;
  cursor: pointer;
  text-align: left;
  font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  width: 196px;
  height: 50px;
  padding: 15px 3px 15px 0;
}

.main_menu {
  margin-right: 15px;
  float: right;
}

.submenu {
  border-left: 1px solid #425668;
  bottom: 50%;
  content: "";
  left: 35px;
  position: absolute;
  top: 0;
}

.submenu-circle {
  background: #425668;
  bottom: auto;
  content: "";
  height: 8px;
  left: 31px;
  margin-top: 5px;
  position: absolute;
  right: auto;
  width: 8px;
  z-index: 1;
  border-radius: 50%;
}

.sidebar-submenu-list-item {
  position: relative;
  display: block;
  cursor: pointer;
  text-align: left;
  font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  width: 196px;
  height: 40px;
  padding: 15px 3px 15px 15px !important;
}

.listing-table-column {
  text-align: left !important;
}

.listing-table-head-column {
  text-align: left !important;
}

.sidebar-submenu-list-item  {
  display: none;
}

.has-submenu.active .sidebar-submenu-list-item  {
  display: block;
}