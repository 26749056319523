@import "./v";

#opl_coremax {
	background-color: white;
	* {
		margin: 0;
		padding: 0;
		@include vendorPrefix("box-sizing", "border-box");
	}
	html {
		scroll-behavior: smooth;
	}
	body {
		height: auto;
		overflow: auto;
		position: relative;
		background: white;
		background-image: none !important;
		color: #17181f;
		font-family: "Inter", sans-serif;
	}
	h1 {
		font-size: 36px;
	}
	h2 {
		font-size: 32px;
	}
	h3 {
		font-size: 28px;
	}
	h4 {
		font-size: 24px;
	}
	h5 {
		font-size: 20px;
	}
	h6 {
		font-size: 16px;
	}
	p {
		font-size: 14px;
		line-height: 1.5;
	}
	p.small {
		font-size: 12px;
	}
	a {
		color: inherit;
		text-decoration: none;
	}
	.container {
		max-width: 1120px;
		margin: 0 auto;
	}
	.secondary-container {
		max-width: 980px;
		margin: 0 auto;
	}
	.f-w-200 {
		font-weight: 200;
	}
	.f-w-300 {
		font-weight: 300;
	}
	.f-w-400 {
		font-weight: 400;
	}
	.f-w-500 {
		font-weight: 500;
	}
	.f-w-600 {
		font-weight: 600;
	}
	.f-w-700 {
		font-weight: 700;
	}
	.f-w-800 {
		font-weight: 800;
	}
	.f-w-900 {
		font-weight: 900;
	}
	.pad-20 {
		padding: 20px;
	}
	.pad-10 {
		padding: 10px;
	}
	.pad-10-0 {
		padding: 10px 0px;
	}
	.pad-0-10 {
		padding: 0 10px;
	}
	.pad-5 {
		padding: 5px;
	}
	.pad-5-0 {
		padding: 5px 0;
	}
	.pad-0-5 {
		padding: 0 5px;
	}
	.default-image {
		background: url(../static/default-image.png) no-repeat center center;
		background-position: center center;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.main-section {
		padding-top: 70px;
		overflow: hidden;
	}
	.b-r-5 {
		@include vendorPrefix("border-radius", "5px");
	}
	.b-r-8 {
		@include vendorPrefix("border-radius", "8px");
	}
	.b-r-10 {
		@include vendorPrefix("border-radius", "10px");
	}
	.b-r-20 {
		@include vendorPrefix("border-radius", "20px");
	}
	.b-r-100 {
		@include vendorPrefix("border-radius", "100%");
	}
	.b {
		@include vendorPrefix("border", " solid 1px");
	}
	.flex {
		display: flex;
	}
	.dis-b {
		display: block;
	}
	.dis-i-b {
		display: inline-block;
	}
	.center-center {
		justify-content: center;
		align-items: center;
	}
	.center-left {
		justify-content: left;
		align-items: center;
	}
	.top-left {
		justify-content: flex-start;
		align-items: flex-start;
	}
	.center-right {
		justify-content: right;
		align-items: center;
	}
	.bottom-left {
		justify-content: flex-start;
		align-items: flex-end;
	}
	.bottom-right {
		justify-content: flex-end;
		align-items: flex-end;
	}
	.l-s-n {
		list-style: none;
	}
	.flex-1 {
		flex: 1;
	}
	.col-1-5 {
		width: 20%;
	}
	.col-1-4 {
		width: 25%;
	}
	.col-3-10 {
		width: 30%;
	}
	.col-1-3 {
		width: 33.33%;
	}
	.col-2-5 {
		width: 40%;
	}
	.col-1-2 {
		width: 50%;
	}
	.col-3-5 {
		width: 60%;
	}
	.col-2-3 {
		width: 66.66%;
	}
	.col-7-10 {
		width: 70%;
	}
	.col-3-4 {
		width: 75%;
	}
	.col-4-5 {
		width: 80%;
	}
	.col-1-1 {
		width: 100%;
	}
	.pos-rel {
		position: relative;
	}
	.of-hid {
		overflow: hidden;
	}
	.of-vis {
		overflow: visible;
	}
	.t-t-u {
		text-transform: uppercase;
	}
	.t-a-c {
		text-align: center;
	}
	.t-a-r {
		text-align: right;
	}
	.t-a-l {
		text-align: left;
	}
	.t-d-ul {
		text-decoration: underline;
	}
	.hover-t-d-ul {
		&:hover {
			text-decoration: underline;
		}
	}
	.l-h-1 {
		line-height: 1;
	}
	.l-h-1-2 {
		line-height: 1.2;
	}
	.l-h-1-5 {
		line-height: 1.5;
	}
	.l-h-2 {
		line-height: 2;
	}
	.cursor-pointer {
		cursor: pointer;
	}
	.cursor-not-allowed {
		cursor: not-allowed;
	}
	.color-white {
		color: white;
	}
	.color-primary {
		color: $colorPrimary !important;
	}
	.color-secondary {
		color: $colorSecondary;
	}
	.color-tertiary {
		color: $colorTertiary;
	}
	.color-faded {
		color: hsl(240, 20%, 96%);
	}
	.color-light {
		color: $colorLight;
	}
	.mar-l-a {
		margin-left: auto;
	}
	.mar-10-0 {
		margin: 10px 0;
	}
	.mar-20-0 {
		margin: 20px 0;
	}
	.transition {
		@include vendorPrefix("transition", "all 0.2s");
	}
	.hover-color-secondary {
		&:hover {
			color: $colorSecondary;
		}
	}
	.hover-color-primary {
		&:hover {
			color: $colorPrimary;
		}
	}
	.hover-color-white {
		&:hover {
			color: white;
		}
	}
	.hover-background-primary {
		&:hover {
			background-color: $colorPrimary;
		}
	}
	.hover-background-secondary {
		&:hover {
			background-color: $colorSecondary;
		}
	}
	.bg-color-primary {
		background-color: $colorPrimary;
	}
	.bg-color-secondary {
		background-color: $colorSecondary;
	}
	.bg-color-tertiary {
		background-color: $colorTertiary;
	}
	.bg-color-faded {
		background-color: #f3f4f6;
	}
	.font-size-14 {
		font-size: 14px;
	}
	.font-size-12 {
		font-size: 12px;
	}
	.color-faded {
		color: #8f8f8f;
	}
	.page-active {
		margin: 5px 10px;
		padding: 0 10px;
		border-left: solid #7af1f7;
		border-width: 4px;
		color: $colorPrimary;
	}

	// ====== Form Fields ====== //

	input,
	select,
	textarea,
	button {
		border: none;
		outline: none;
		resize: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		-ms-appearance: none;
		appearance: none;
	}
	button::-moz-focus-inner,
	input::-moz-focus-inner,
	select::-moz-focus-inner,
	textarea::-moz-focus-inner {
		outline: none !important;
	}
	select:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 #ffffff;
	}
	textarea {
		-webkit-rtl-ordering: logical;
		-moz-rtl-ordering: logical;
		-o-rtl-ordering: logical;
		-ms-rtl-ordering: logical;
		-rtl-ordering: logical;
		-webkit-user-select: text;
		-moz-user-select: text;
		-o-user-select: text;
		-ms-user-select: text;
		user-select: text;
	}
	textarea::-ms-expand {
		display: none;
	}
	::-webkit-input-placeholder {
		color: #8f8f8f;
	}
	::-moz-placeholder {
		color: #8f8f8f;
	}
	::-o-placeholder {
		color: #8f8f8f;
	}
	:-ms-input-placeholder {
		color: #8f8f8f;
	}
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 100px #ffffff inset !important;
		-webkit-text-fill-color: #444 !important;
	}
	input.primary,
	select.primary,
	textarea.primary,
	.file-input-holder,
	.ui-like-input {
		padding: 0 16px;
		line-height: 38px;
		height: 40px;
		font-size: 14px;
		@include vendorPrefix("border-radius", "4px");
		width: 100%;
		display: block;
		@include vendorPrefix("transition", "all 0.2s");
		border: solid 1px;
		color: #373737;
		border-color: #cfcfcf;
		background-color: transparent;
		font-family: "Inter", sans-serif;
		font-weight: 300;
		overflow: hidden;
		background-color: white;
	}

	.cursor-not-allowed {
		cursor: not-allowed !important;
	}

	select.primary {
		padding: 0 32px 0 16px;
		background: white url("../assets/images/icons/select.svg") right center
			no-repeat;
		cursor: pointer;
	}

	input[type="checkbox"],
	input[type="radio"] {
		height: 24px;
		width: 24px;
		padding: 0;
		position: relative;
		cursor: pointer;
		@include vendorPrefix("transition", "none");
	}
	input[type="checkbox"] {
		@include vendorPrefix("border-radius", "4px");
	}
	input[type="checkbox"],
	input[type="checkbox"]:focus,
	input[type="radio"],
	input[type="radio"]:focus {
		border-color: $colorPrimary;
	}
	input[type="checkbox"]:checked {
		border-color: $colorPrimary;
		background-color: $colorPrimary;
	}
	input[type="checkbox"]:checked:before,
	input[type="checkbox"]:checked:after,
	input[type="radio"]:checked:before,
	input[type="radio"]:checked:after {
		position: absolute;
		content: "";
	}
	input[type="checkbox"]:checked:before,
	input[type="checkbox"]:checked:after {
		@include vendorPrefix("transform-origin", "top left");
		@include vendorPrefix(
			"transform",
			"rotate(-45deg) translateY(13px) translateX(-6px)"
		);
	}
	input[type="checkbox"]:checked:before {
		height: 6px;
		width: 12px;
		left: 0;
		top: -2px;
		background: white;
	}
	input[type="checkbox"]:checked:after {
		height: 6px;
		width: 12px;
		left: 0px;
		top: -4px;
		background: $colorPrimary;
	}
	input[type="radio"],
	input[type="radio"]:checked:before,
	input[type="radio"]:checked:after {
		@include vendorPrefix("border-radius", "100%");
	}
	input[type="radio"]:checked {
		border-color: $colorPrimary;
	}
	input[type="radio"]:checked:before {
		height: 18px;
		width: 18px;
		top: 2px;
		left: 2px;
		background: $colorSecondary;
	}
	input[type="radio"]:checked:after {
		height: 14px;
		width: 14px;
		top: 4px;
		left: 4px;
		background: $colorPrimary;
	}
	.file-input-holder {
		cursor: pointer;
		input[type="file"] {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 3;
			@include opacity0();
		}
		svg {
			position: absolute;
			height: 16px;
			width: auto;
			display: block;
			top: 16px;
			right: 12px;
			path {
				fill: $colorPrimary;
			}
		}
	}
	textarea.primary {
		height: 100px;
		padding: 16px;
		line-height: 16px;
		overflow: scroll;
	}
	input.error,
	select.error,
	textarea.error {
		border-width: 1px !important;
		border-color: red;
	}

	.react-datepicker {
		button.react-datepicker__navigation {
			background-color: #f0f0f0;
			border: solid 1px #cfcfcf;
			padding: 0;
			height: auto;
			@include vendorPrefix("border-radius", "2px");
			width: 24px;
			line-height: 24px;
			&:after {
				position: absolute;
				height: 24px;
				width: 24px;
				left: 0;
				top: 0;
				color: $colorPrimary;
				font-family: "Inter", sans-serif;
				z-index: 3;
				font-size: 12px;
				font-weight: 900;
				line-height: 24px;
				text-indent: initial;
			}
		}
		button.react-datepicker__navigation--previous {
			&:after {
				content: "<";
			}
		}
		button.react-datepicker__navigation--next {
			&:after {
				content: ">";
			}
		}
	}

	.react-datepicker-wrapper {
		button.react-datepicker__close-icon {
			height: 40px;
			background-color: #f0f0f0;
			@include vendorPrefix("border-radius", "0 4px 4px 0");
			border: solid 1px #cfcfcf;
			border-left: none;
		}
	}
	
	p.error-messages {
		font-size: 11px;
		padding: 5px 0;
		line-height: 1.2;
		color: red;
	}

	p.success-messages {
		font-size: 11px;
		padding: 5px 0;
		line-height: 1.2;
		color: rgb(23, 149, 23);
	}

	hr.primary {
		outline: none;
		border: none;
		height: 1px;
		background-color: #e3e3e3;
	}

	button {
		border: none;
		outline: none;
		cursor: pointer;
		padding: 0 12px;
		height: 36px;
		line-height: 36px;
		font-size: 14px;
		font-family: "Inter", sans-serif;
		font-weight: 400;
		cursor: pointer;
		@include vendorPrefix("transition", "all 0.2s");
		@include vendorPrefix("border-radius", "6px");
		background-color: $colorPrimary;
		color: #fff;
		span {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			img {
				height: 16px;
				width: auto;
			}
			svg {
				height: 18px;
				width: auto;
				path {
					fill: white;
					@include vendorPrefix("transition", "all 0.2s");
				}
			}
		}
		&:hover {
			color: $colorSecondary;
			span {
				svg {
					path {
						fill: $colorSecondary;
					}
				}
			}
		}
		&:disabled {
			background-color: #aaa;
			color: #cfcfcf;
			cursor: not-allowed;
		}
	}

	button.light {
		background-color: #f3f4f6ff;
		color: $colorPrimary;
		span {
			svg {
				path {
					fill: $colorPrimary;
				}
			}
		}
		&:hover {
			color: white;
			background-color: $colorSecondary;
			svg {
				path {
					fill: white;
				}
			}
		}
	}

	button.tertiary {
		height: 32px;
		line-height: 32px;
		color: white;
		@include vendorPrefix("border-radius", "16px");
		padding: 0 16px;
		font-size: 12px;
		svg {
			height: 10px;
			width: auto;
			display: block;
			path {
				fill: white;
			}
		}
		&:hover {
			color: #f0f0f0;
			svg {
				path {
					fill: #f0f0f0;
				}
			}
		}
		&:disabled {
			background-color: #aaa;
			color: #cfcfcf;
			cursor: not-allowed;
		}
	}

	button.tertiary-view {
		background-color: #27ae60;
	}
	button.tertiary-edit {
		background-color: #56baec;
	}
	button.tertiary-delete {
		background-color: #e65464;
	}
	button.tertiary-reload {
		background-color: #6127ae;
	}
	button.tertiary-download{
		background-color: #0d6efd;
	}
	button.tertiary-sm {
		height: 24px;  
		line-height: 24px;  
		color: white;
		border-radius: 12px;  
		padding: 0 12px; 
		font-size: 10px; 
	}

	// =================================================
	// ================= Header
	// =================================================

	header {
		padding: 12px 16px 6px 16px;
		width: 100%;
		margin-bottom: 0 !important;
		@include vendorPrefix("box-shadow", "0 0 6px rgba(0,0,0,0.08)");
		z-index: 9999;
		position: relative;
		background-color: white;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		.menu-btn {
			height: 36px;
			width: 36px;
			background-color: #f0f0f0;
			cursor: pointer;
			margin-right: 20px;
			@include vendorPrefix("border-radius", "4px");
			border: solid 1px #d8d8d8;
			span {
				height: 2px;
				width: 16px;
				background-color: $colorPrimary;
				margin: 4px 0;
				display: block;
				@include vendorPrefix("transition", "all 0.4s");
			}
		}
		.menu-btn-open {
			span {
				margin: 2px 0;
			}
		}
		.top {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			.logo {
				img {
					height: 48px;
					width: auto;
					display: block;
				}
			}
			.right {
				margin-left: auto;
				display: flex;
				align-items: center;
				gap: 20px;
				ul.actions {
					gap: 20px;
					li {
						list-style: none;
						position: relative;
						svg {
							height: 24px;
							width: auto;
							display: block;
						}
						ul {
							position: absolute;
							max-height: 186px;
							overflow-y: scroll;
							background-color: white;
							border: solid 1px #f0f0f0;
							@include vendorPrefix(
								"box-shadow",
								"0 0 20px rgba(0,0,0,0.13)"
							);
							right: 0;
							top: 36px;
							white-space: nowrap;
							@include vendorPrefix(
								"transition-timing-function",
								"ease-in"
							);
							@include vendorPrefix(
								"animation",
								"submenu-slide-from-bottom 0.2s"
							);
							@include vendorPrefix(
								"animation-fill-mode",
								"both"
							);
							li {
								list-style: none;
								padding: 0 32px 0 16px;
								line-height: 40px;
								border-bottom: solid 1px #f0f0f0;
								font-size: 13px;
								color: $colorPrimary;
								&:hover {
									color: $colorSecondary;
								}
							}
						}
					}
				}
			}
		}
	}

	// =================================================
	// ================= Navigation
	// =================================================

	.sidebar-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.72);
		z-index: 9999;
	}

	nav {
		z-index: 1;
		left: -280px;
		position: fixed;
		height: 100vh;
		top: 0px;
		width: 240px;
		@include vendorPrefix("transition", "all 0.4s");
		padding: 132px 0 280px 0;
		overflow-y: scroll;
		background-color: $colorPrimary;
		ul {
			li {
				list-style: none;
				position: relative;
				white-space: nowrap;
				border-bottom: solid 1px rgba(255, 255, 255, 0.04);
				&:first-child {
					border-top: solid 1px rgba(255, 255, 255, 0.04);
				}
				a {
					position: relative;
					font-size: 13px;
					line-height: 48px;
					display: block;
					color: white;
					padding: 0 16px 0 14px;
					span {
						gap: 10px;
					}
					svg {
						height: 12px;
						width: 12px;
						path {
							fill: $colorSecondary;
						}
					}
					&:hover {
						color: $colorSecondary;
					}
					svg.arr {
						width: 8px;
						height: 5px;
					}
					svg.arr-open {
						@include vendorPrefix("transform", "rotate(180deg)");
					}
				}
				ul {
					li {
						display: block;
						border-bottom: solid 1px rgba(255, 255, 255, 0.04);
						&:last-child {
							border-bottom: none;
						}
						a {
							padding: 0 12px 0 38px;
							display: block;
							line-height: 36px;
							font-size: 13px;
						}
					}
				}
				a.active {
					border: none;
					&:after {
						position: absolute;
						content: "";
						bottom: 0;
						left: 0;
						height: 3px;
						width: 100%;
						background-color: $colorPrimary;
						@include vendorPrefix("border-top-left-radius", "4px");
						@include vendorPrefix("border-top-right-radius", "4px");
					}
					&:hover {
						color: $colorPrimary;
						svg {
							path {
								fill: $colorPrimary;
							}
						}
					}
				}
				&:last-child {
					&:after {
						display: none;
					}
				}
			}
		}
	}

	nav.menu-open {
		left: 0;
	}

	// =================================================
	// ================= Header
	// =================================================

	.notification {
		position: relative;
		.new {
			&:after {
				position: absolute;
				content: "";
				top: -2px;
				right: 0px;
				height: 8px;
				width: 8px;
				@include vendorPrefix("border-radius", "100%");
				background-color: orangered;
				border: solid 1px $colorPrimary;
			}
		}
		.list {
			position: absolute;
			background-color: #ffffff;
			border: solid 1px #f0f0f0;
			right: 0px;
			top: 40px;
			@include vendorPrefix("border-radius", "6px");
			padding: 0;
			width: 300px;
			z-index: 99;
			@include vendorPrefix("box-shadow", "0 0 20px rgba(0,0,0,0.08)");
			ul.tab {
				border-bottom: solid 1px #f0f0f0;
				display: flex;
				gap: 10px;
				padding: 4px 4px 0 16px;
				li {
					list-style: none;
					position: relative;
					p {
						font-size: 13px;
						color: $colorPrimary;
						line-height: 1;
						padding: 16px 10px;
						display: block;
						cursor: pointer;
					}
				}
				li.active:after {
					position: absolute;
					content: "";
					bottom: 0;
					left: 0;
					height: 2px;
					width: 100%;
					background-color: #7af1f7;
				}
				svg.close {
					height: 24px;
					width: auto;
					display: block;
					cursor: pointer;
					margin-left: auto;
					margin-top: 8px;
					margin-right: 4px;
					@include vendorPrefix("transition", "all 0.2s");
					&:hover {
						@include vendorPrefix("transform", "scale(1.12)");
					}
					path {
						fill: $colorSecondary;
					}
				}
			}
			.lists-holder {
				height: 300px;
				overflow-y: scroll;
				.no-data {
					height: 300px;
					display: flex;
					align-items: center;
					justify-content: center;
					.i {
						height: 40px;
						width: 40px;
						display: flex;
						margin: 0 auto;
						@include vendorPrefix("border-radius", "100%");
						background-color: rgba(74, 74, 74, 0.12);
						justify-content: center;
						align-items: center;
						margin-bottom: 10px;
						svg {
							height: 20px;
							width: auto;
							display: block;
							path {
								fill: $colorPrimary;
							}
						}
					}
				}
			}
			ul.lists {
				display: block;
				li {
					list-style: none;
					border-bottom: solid 1px #f0f0f0;
					width: 100%;
					display: flex;
					align-items: center;
					padding: 12px 16px;
					cursor: pointer;
					position: relative;
					.ico {
						height: 36px;
						width: 36px;
						border: solid 1px;
						@include vendorPrefix("border-radius", "100%");
						display: flex;
						align-items: center;
						justify-content: center;
						svg {
							height: 16px;
							width: auto;
							display: block;
						}
					}
					.ico-success {
						border-color: #cfcfcf;
						background-color: #f0f0f0;
					}
					.ico-fail {
						border-color: #cfcfcf;
						background-color: #f0f0f0;
					}
					.t {
						padding-left: 12px;
						padding-right: 48px;
						@include calc("width", "100% - 36px");
						p.m {
							font-size: 12px;
							line-height: 1.2;
						}
						p.d {
							font-size: 9px;
							color: #646464;
						}
					}
					.del {
						position: absolute;
						right: 10px;
						top: 50%;
						margin-top: -16px;
						height: 32px;
						width: 32px;
						@include vendorPrefix("border-radius", "100%");
						z-index: 2;
						cursor: pointer;
						@include vendorPrefix("transition", "all 0.2s");
						padding-top: 10px;
						svg {
							height: 12px;
							width: auto;
							margin: 0 auto;
							display: block;
							path {
								fill: $colorPrimary;
							}
						}
						display: none;
						&:hover {
							background: white;
							svg {
								path {
									fill: $colorSecondary;
								}
							}
						}
					}
					&:hover {
						background-color: #fafafa;
						.del {
							display: block;
						}
					}
				}
			}
		}
	}

	.profile-info {
		position: absolute;
		background-color: #ffffff;
		border: solid 1px #f0f0f0;
		right: 0px;
		top: 40px;
		@include vendorPrefix("border-radius", "6px");
		padding: 0;
		width: 160px;
		z-index: 99;
		@include vendorPrefix("box-shadow", "0 0 20px rgba(0,0,0,0.08)");
		ul {
			li {
				display: block;
				font-size: 13px;
				list-style: none;
				border-bottom: solid 1px #f0f0f0;
				text-align: right;
				a {
					display: block;
					padding: 12px 16px;
				}
				&:last-child {
					border-bottom: none;
				}
			}
			li.active {
				background: rgba(0, 0, 0, 0.02);
				color: $colorSecondary;
			}
		}
	}

	// =================================================
	// ================= Custom Tooltip
	// =================================================

	.opl-tooltip {
		position: relative;
		cursor: pointer;
		&:after,
		&:before {
			position: absolute;
		}
		&:after {
			content: attr(data-tooltip);
			background-color: $colorPrimary;
			@include vendorPrefix("border-radius", 4px);
			height: auto;
			padding: 12px 20px;
			z-index: 99 !important;
			font-size: 13px;
			color: #fff;
			font-weight: 300;
			text-align: center;
			@include calc("top", "100% + 12px");
			left: 50%;
			width: 200px;
			margin-left: -100px;
			display: none;
			line-height: 1.2;
		}
		&:before {
			position: absolute;
			content: "";
			height: 0;
			width: 0;
			border-left: solid 10px transparent;
			border-right: solid 10px transparent;
			border-bottom: solid 10px #233647;
			bottom: -12px;
			left: 50%;
			margin-left: -5px;
			display: none;
		}
		&:hover {
			&:after,
			&:before {
				display: block;
				@include vendorPrefix("transition-timing-function", "ease-in");
				@include vendorPrefix(
					"animation",
					"submenu-slide-from-bottom 0.2s"
				);
				@include vendorPrefix("animation-fill-mode", "both");
			}
		}
	}

	// =================================================
	// ================= Custom Tooltip
	// =================================================

	.opl-file-drag-upload {
		height: 186px;
		width: 100%;
		border: dashed 2px #dfdfdf;
		background-color: white;
		input {
			position: absolute;
			inset: 0;
			z-index: 1;
			@include opacity0();
		}
	}

	.error-border {
		border: dashed 2px red;
	}

	// ====== Search Bar ====== //

	.search-bar {
		.row {
			display: flex;
			align-items: center;
			input {
				background-color: white;
				border-color: #e8e8e8;
				@include vendorPrefix("border-top-right-radius", "0px");
				@include vendorPrefix("border-bottom-right-radius", "0px");
			}
			button {
				@include vendorPrefix("border-top-left-radius", "0px");
				@include vendorPrefix("border-bottom-left-radius", "0px");
				background-color: $colorSecondary;
				&:hover {
					background-color: $colorPrimary;
				}
			}
		}
	}

	// =========================== //
	// ====== Tabs ====== //
	// =========================== //

	ul.tab-selector {
		display: flex;
		align-items: center;
		gap: 16px;
		li {
			list-style: none;
			font-size: 13px;
			color: $colorLight;
			padding: 0 12px;
			line-height: 40px;
			&:hover {
				color: $colorSecondary;
			}
		}
		li.active-tab {
			&:after {
				position: absolute;
				bottom: 0;
				left: 0;
				height: 3px;
				width: 100%;
				background-color: $colorPrimary;
				content: "";
				@include vendorPrefix("border-top-left-radius", "3px");
				@include vendorPrefix("border-top-right-radius", "3px");
			}
		}
	}

	// ====== RS Table ====== //

	.rs-table-holder {
		padding: 0px 20px 20px 20px;
		overflow-x: scroll;
	}

	table.rs-table {
		width: 100%;
		display: table;
		border-collapse: collapse;
		border: solid 1px #f9f9fa;
		@include vendorPrefix("border-top-left-radius", "10px");
		@include vendorPrefix("border-bottom-left-radius", "10px");
		thead {
			tr {
				th {
					background-color: $colorPrimary;
					text-align: left;
					color: white;
					font-size: 14px;
					font-weight: 600;
					padding: 16px 20px;
					white-space: nowrap;
					text-transform: uppercase;
					&:first-child {
						@include vendorPrefix("border-top-left-radius", "8px");
					}
					&:last-child {
						@include vendorPrefix("border-top-right-radius", "8px");
					}
				}
			}
		}
		tbody {
			tr {
				td {
					cursor: pointer;
					color: $colorPrimary !important;
					text-align: left;
					font-size: 14px;
					font-weight: 400;
					padding: 12px 12px 12px 16px;
					white-space: nowrap;
					border-bottom: solid 1px #f0f0f0;
					text-transform: uppercase;
					.status {
						@include vendorPrefix("border-radius", "15px");
						padding: 0 15px;
						width: auto;
						display: inline-block;
						font-size: 13px;
						line-height: 30px;
						color: white;
					}
					.active {
						background-color: $colorSecondary !important;
						border-right: 0px;
					}
					.inactive {
						background-color: $colorPrimary;
					}
				}
				td.bold {
					font-weight: 700;
				}
				&:nth-child(2n) {
					background-color: #f9f9fa;
				}
				&:hover {
					background-color: #e6ffff;
				}
			}
		}
	}

	// ====== Table Pagination ====== //

	ul.opl-pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 4px;
		li {
			list-style: none;
			cursor: pointer;
			display: block;
			width: 24px;
			height: 24px;
			border: solid 1px;
			border-color: #dfe2e7 !important;
			text-align: center;
			font-size: 9px;
			font-weight: 700;
			line-height: 24px;
			color: $colorPrimary;
			font-family: "Inter", sans-serif;
			justify-content: center;
			@include vendorPrefix("transition", "all 0.2s");
			@include vendorPrefix("border-radius", "4px");
			&:hover {
				background-color: #dfe2e7 !important;
			}
			svg {
				height: 8px;
				width: 5px;
				display: block;
				margin: 0 auto;
				margin-top: 7px;
				path {
					fill: $colorTertiary;
				}
			}
		}
		li.active {
			border-color: $colorPrimary !important;
			background-color: $colorPrimary !important;
			color: white !important;
		}
	}

	// ====== Custom Modal ====== //

	#custom-modal {
		position: fixed;
		inset: 0;
		.inner {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100vh;
			width: 100vw;
			background-color: rgba(255, 255, 255, 0.92);
			.box {
				position: relative;
				background-color: white;
				border: solid 1px #dfe2e7;
				@include vendorPrefix("border-radius", "10px");
			}
		}
	}

	// ====== RS Search Bar ====== //

	.top-search-bar {
		display: flex;
		padding: 25px 20px;
		border-bottom: solid 2px #fafafbff;
		align-items: center;
		.ip-holder {
			margin-left: auto;
			width: 368px;
		}
	}

	// ====== RS Full Loader ====== //

	#rs-loader-overlay {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		position: fixed;
		background: white;
		z-index: 99999;
		@include opacity(0.68, 68);
		.inner {
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			.content {
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				.spinner {
					width: 40px;
					height: 40px;
					display: inline-block;
					border: solid 2px $colorPrimary;
					border-top-color: #fff;
					@include vendorPrefix(
						"animation",
						"spin 1s infinite linear"
					);
				}
			}
		}
	}

	@-webkit-keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@-moz-keyframes spin {
		100% {
			-moz-transform: rotate(360deg);
		}
	}
	@-o-keyframes spin {
		100% {
			-o-transform: rotate(360deg);
		}
	}
	@-ms-keyframes spin {
		100% {
			-ms-transform: rotate(360deg);
		}
	}
	@keyframes spin {
		100% {
			transform: rotate(360deg);
		}
	}

	// ====== RS Search Results ====== //

	.search-results {
		background-color: white;
		padding: 0;
		border: solid 1px #f3f4f6;
		@include vendorPrefix("box-shadow", "0 0 10px rgba(0,0,0,0.04)");
		@include vendorPrefix("border-radius", "4px");
		width: 100%;
		height: 192px;
		position: absolute;
		z-index: 999;
		top: 46px;
		overflow-y: scroll;
		ul {
			li {
				border-bottom: solid 1px #f3f4f6;
				line-height: 1;
				padding: 12px 20px;
				font-size: 14px;
				&:last-child {
					border-bottom: none;
				}
				&:hover {
					background-color: #f3f4f6;
					color: $colorSecondary;
				}
			}
		}
	}

	@-webkit-keyframes slide-from-right {
		0% {
			-webkit-transform: translateX(200px);
		}
		100% {
			-webkit-transform: translateX(0px);
		}
	}
	@-moz-keyframes slide-from-right {
		0% {
			-moz-transform: translateX(200px);
		}
		100% {
			-moz-transform: translateX(0px);
		}
	}
	@-o-keyframes slide-from-right {
		0% {
			-o-transform: translateX(200px);
		}
		100% {
			-o-transform: translateX(0px);
		}
	}
	@-ms-keyframes slide-from-right {
		0% {
			-ms-transform: translateX(200px);
		}
		100% {
			-ms-transform: translateX(0px);
		}
	}
	@keyframes slide-from-right {
		0% {
			transform: translateX(200px);
		}
		100% {
			transform: translateX(0px);
		}
	}

	@-webkit-keyframes diminish {
		0% {
			width: 100%;
		}
		100% {
			width: 0;
		}
	}
	@-moz-keyframes diminish {
		0% {
			width: 100%;
		}
		100% {
			width: 0;
		}
	}
	@-o-keyframes diminish {
		0% {
			width: 100%;
		}
		100% {
			width: 0;
		}
	}
	@-ms-keyframes diminish {
		0% {
			width: 100%;
		}
		100% {
			width: 0;
		}
	}
	@keyframes diminish {
		0% {
			width: 100%;
		}
		100% {
			width: 0;
		}
	}

	// =================================================
	// ================= paginated dropdown
	// =================================================

	.dropdown-container {
		input {
			font-size: 13px;
		}
	}
	.dropdown-options {
		position: absolute;
		width: 100%;
		max-height: 200px;
		overflow-y: auto;
		overflow-x: hidden;
		border: 1px solid #ccc;
		@include vendorPrefix("border-radius", "4px");
		background: white;
		z-index: 10;
		&::-webkit-scrollbar {
			width: 5px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #aaa;
			border-radius: 10px;
			&:hover {
				background-color: #888;
			}
		}
	}
	.dropdown-option {
		border-bottom: solid 1px #f0f0f0;
		padding: 5px 12px;
		// line-height: 32px;
		font-size: 12px;
		color: $colorPrimary;
		&:hover {
			background-color: #f0f0f0;
		}
		&.selected {
			background-color: #e0e0e0;
		}
	}

	// =================================================
	// ================= Toast Messages
	// =================================================

	.toast-messages {
		position: fixed;
		z-index: 99999999 !important;
		top: 20px;
		right: 20px;

		.single-toast {
			@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.08)");
			@include vendorPrefix("border-radius", "6px");
			width: 280px;
			padding: 15px 20px 15px 12px;
			background-color: $colorPrimary;
			margin-bottom: 12px;
			position: relative;
			cursor: pointer;
			overflow: hidden;
			@include vendorPrefix("animation", "slide-from-right 0.4s");
			@include vendorPrefix("animation-fill-mode", "both");
			display: flex;
			align-items: center;
			gap: 12px;

			&:last-child {
				margin-bottom: 0;
			}

			.icon {
				background-color: #fafafa;
				height: 60px;
				width: 60px;
				@include vendorPrefix("border-radius", "100%");
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: white;

				svg {
					height: 48px;
					width: 48px;
					display: block;
					margin: 0 auto;
				}
			}

			p {
				font-size: 14px;
				line-height: 1.2;
				font-weight: 300;
				flex: 1;
			}

			hr {
				outline: none;
				border: none;
				height: 4px;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				background-color: $colorSecondary;
				@include vendorPrefix("animation", "diminish 4s");
				@include vendorPrefix("animation-fill-mode", "both");
			}
		}
	}

	// =================================================
	// ================= Filters
	// =================================================

	.filters {
		background-color: white;
		@include vendorPrefix("box-shadow", "inset 0 0 6px rgba(0,0,0,0.08)");
		padding: 16px 16px;
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(auto-fill, auto);
		grid-column-gap: 10px;
		grid-row-gap: 8px;
	}

	// =================================================
	// ================= End
	// =================================================

	//multi-select-container----------------

	.multi-select-container {
		.multi-select-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;
			border: 1px solid #ccc;
			border-radius: 4px;
			background-color: #f3f4f6;
			cursor: pointer;

			.multi-select-title {
				font-size: 14px;
				color: #333;
			}

			.multi-select-arrow {
				font-size: 12px;
				color: #777;
			}
		}

		.multi-select-dropdown {
			border: 1px solid #ccc;
			border-top: none;
			border-radius: 0 0 4px 4px;
			background-color: #f3f4f6;
			box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
			max-height: 240px;
			overflow-y: auto;

			.multi-select-option {
				padding: 8px 10px;
				display: flex;
				align-items: center;

				label {
					margin-left: 8px;
					font-size: 14px;
					color: #333;
				}

				&:hover {
					background-color: #f3f4f6;
				}
			}
		}

		input[type="checkbox"] {
			cursor: pointer;
		}
	}
}

//=============Accordion================//

.accordion {
	// max-width: 600px;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

	.accordion-item {
		border-bottom: 1px solid #e2e8f0;

		.accordion-header {
			border-radius: 0px !important;
			width: 100% !important;
			padding: 24px !important;
			font-weight: 500 !important;
			font-size: 1rem !important;
			display: flex !important;
			justify-content: space-between !important;
			align-items: center !important;
			transition: background-color 0.2s !important;

			&::after {
				content: "+";
				font-size: 1.25rem;
				transition: transform 0.3s ease;
			}

			&.active-accordion::after {
				transform: rotate(45deg);
			}
		}

		.accordion-content {
			max-height: 0;
			overflow: hidden;
			padding: 0 1rem;
			font-size: 0.95rem;
			transition: max-height 0.3s ease, padding 0.3s ease;

			&.open {
				width: 100%;
				max-height: 1000px;
				height: auto;
				padding: 1rem;
			}
		}
	}
}

/******************* Date Range Style ***********************/
.date-range {

	.rdrDefinedRangesWrapper {
		.rdrStaticRanges {
			.rdrStaticRange { 
				color: $colorPrimary !important;
				border: 0 !important;
				cursor: pointer !important;
				display: block !important;
				outline: 0 !important;
				border-bottom: 1px solid #eff2f7 !important;
				padding: 0 !important;
				background: #fff !important;

				.rdrStaticRangeLabel {
					background-color: transparent !important;
				}

				.rdrStaticRangeLabel:hover {
					background-color: transparent !important;
				}

				.rdrStaticRangeLabel:focus {
					background-color: transparent !important;
				}
			}
			.rdrStaticRange:hover{
				background-color: #eff2f7 !important;
				color: white;
			} 
			.rdrStaticRangeSelected{
				font-weight: 600 !important;
				color: $colorSecondary !important;
			}
		}
	}


	.rdrInputRangeInput {
		width: 30px !important;
		height: 30px !important;
		line-height: 30px !important;
		border-radius: 4px !important;
		text-align: center !important;
		border: solid 1px rgb(222, 231, 235) !important;
		margin-right: 10px !important;
		color: rgb(108, 118, 122) !important;
	}

	.rdrInputRanges {
		padding: 10px 0 !important;

		.rdrInputRange {
			align-items: center !important;
			padding: 5px 20px !important;
			display: flex !important;
		}
	}
 
	.rdrDateDisplayWrapper {
		background-color: #eff2f7 !important; 
		.rdrDateDisplay {
			margin: 0.833em !important;
		}
		.rdrDateDisplayItem + .rdrDateDisplayItem {
			margin-left: 0.833em!important;
		}
		.rdrDateDisplayItem {
			border-radius: 4px !important;
			background-color: rgb(255, 255, 255) !important;
			box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21) !important;
			border: 1px solid transparent !important;
		}
		.rdrDateInput {
			position: relative !important;
		}
		.rdrDateDisplayItem {
			flex: 1 1 !important;
			width: 0 !important;
			text-align: center !important;
			color: inherit !important;
		}
	}

	.rdrMonthAndYearWrapper {
		align-items: center !important;
		height: 60px !important;
		padding-top: 10px !important;
		.rdrMonthAndYearWrapper {
			box-sizing: inherit !important;
			display: flex  !important;
			justify-content: space-between !important;
			.rdrNextPrevButton {
				display: block !important;
				width: 24px !important;
				height: 24px !important;
				margin: 0 0.833em !important;
				padding: 0 !important;
				border: 0 !important;
				border-radius: 5px !important;
				background: #EFF2F7 !important;
			}
			
		}
	}
	 
	.rdrNextPrevButton {
		display: block !important;
		width: 24px !important;
		height: 24px !important;
		margin: 0 0.833em !important;
		padding: 0 !important;
		border: 0 !important;
		border-radius: 5px !important;
		background: #EFF2F7 !important;
		padding: 7px !important;
	}

	.rdrMonthAndYearPickers {
		font-weight: 600 !important;
	}

	.rdrMonthsHorizontal{
		.rdrDays{
			button.rdrDay {
				background-color: white !important;
			}
			button .rdrDay {
				background-color: white !important;
			}
		}
		.rdrDayPassive{
			border-color: white !important;
			border: 0px;
			.rdrInRange{
				background-color: white !important; 
			}
			.rdrEndEdge{
				background-color: white !important; 
			}
			.rdrStartEdge{
				background-color: white !important;
			}
			.rdrInRange.rdrEndEdge.rdrStartEdge{
                  border-color: white !important;
			}
		}
	}
    
	.rdrMonthPicker{
		select{
			padding: 5px 20px !important;
			width: 100px !important;
		}
	}

	.rdrYearPicker{
		select{
			padding: 5px 20px !important;
			width: 100px !important;
		}
	}
	.rdrMonthName {
		text-align: left !important;
		font-weight: 600 !important;
		color: #849095 !important;
		padding: 0.833em !important;
	}
	.rdrMonth{
		padding: 10px !important;
	}

}

