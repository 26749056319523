@import "./v";

@media only screen and (max-width: 1600px) {
	#activity-v2 {
		.activity-cards {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

@media only screen and (max-width: 1200px) {
	#opl_coremax {
		.filters {
			grid-template-columns: repeat(4, 1fr);
		}
	}
	#activity-v2 {
		.activity-cards {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	#scanflow-v2 {
		.full-row {
			.left {
				width: 260px;
				.all {
					.one {
						padding: 12px 12px 16px 12px;
					}
				}
			}
			.right {
				@include calc("width", "100% - 260px");
				.doc-container {
					@include calc("width", "100% - 260px");
				}
				.from-container {
					width: 260px;
				}
			}
		}
	}
}

@media only screen and (max-width: 980px) {
	#scanflow-v2 {
		.full-row {
			display: block;
			.left {
				width: 100%;
				height: auto;
				padding-bottom: 0;
				display: block;
				overflow: hidden;
				.all {
					max-width: 100%;
					display: flex;
					scroll-snap-type: x mandatory;
					gap: 10px;
					overflow-x: scroll;
					.one {
						height: auto;
						min-width: 268px;
						margin-bottom: 0;
					}
				}
			}
			.right {
				width: 100%;
				display: block;
				.doc-container {
					width: 100%;
				}
				.from-container {
					width: 100%;
					padding-bottom: 0;
				}
			}
		}
	}
}

@media only screen and (max-width: 780px) {
	#opl_coremax {
		header {
			padding: 12px 10px 6px 10px;
			.menu-btn {
				margin-right: 10px;
			}
			.top {
				.right {
					gap: 12px;
					ul.actions {
						gap: 8px;
					}
				}
			}
		}
		.filters {
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 4px;
			grid-row-gap: 4px;
		}
	}
	#activity-v2 {
		.activity-cards {
			display: block;
			.activity-card {
				margin-bottom: 8px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.accordion {  
		.accordion-item {  
		  .accordion-header {
			height: auto;
              span{
				 flex: 1;
				 span{
         			padding: 8px 16px !important;
				 }
			  }
		  }
		}
	}
	#add-activity, #add-insurance, #add-patient, #add-physician-assistant {
		.form-holder {
			margin: 0;
			max-width: 100%;
			.inner {
				width: 100%;
				@include vendorPrefix("box-shadow", "none");
				@include vendorPrefix("border-radius", "0");
				padding: 12px;
				margin-top: 10px;
				table {
					tbody {
						tr {
							td {
								width: 100%;
								display: block;
								padding-right: 0;
								padding-bottom: 10px;
								&:nth-child(2) {
									padding-left: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 520px) {
	#opl_coremax {
		header {
			background-color: white;
			padding: 10px;
			.top {
				.logo {
					img {
						height: 36px;
					}
				}
				.right {
					display: none;
				}
			}
		}
		.main-section {
			padding-top: 56px;
		}
		.page-active {
			margin: 10px 0 0 0;
			padding: 0;
			border-left: none;
			text-align: center;
		}
		.main-section-top-row {
			display: block;
			padding-bottom: 10px;
			button {
				margin-top: 10px;
				width: 100%;
			}
		}
		ul.tab-selector {
			display: block;
			margin-top: 10px;
			li {
				border-bottom: solid 1px #f0f0f0;
				&:last-child {
					border-bottom: none;
				}
			}
			li.active-tab {
				&:after {
					height: 40px;
					width: 4px;
					@include vendorPrefix("border-top-left-radius", "0px");
					@include vendorPrefix("border-top-right-radius", "0px");
				}
			}
		}
		.filters {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}

@media only screen and (max-width: 432px) {
	#opl_coremax {
		.filters {
			display: block;
			div {
				margin-bottom: 10px;
			}
		}
	}
	#activity-v2 {
		.activity-cards {
			.activity-card {
				.info-row {
					display: block;
					padding-bottom: 0 !important;
					margin-bottom: 12px;
					.info-row-column {
						&:first-child {
							margin-bottom: 12px;
						}
					}
				}
			}
		}
	}
}
