.manage-activity-right-col {
  min-height: 750px;
  margin-left: 196px;
  background: #F7F7F7 !important;
  padding: 10px 20px 0;
}
.manage-activity-right-col2{
  
  background: #F7F7F7 !important;
  padding: 10px 20px 0;
}
.heading-custom {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
  background: #475f87;
  color: #fff;
  padding: 10px 10px;
}

.activity-row {
  margin-right: -10px;
  margin-left: -10px;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}

.register-row {
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}

.select-procedure {
  margin-right: 36px;
  margin-left: 36px;
  width: 100%;
}

.select-surgeon {
  padding-top: 10px;
  margin-left: 35px;
  width: 100%;
}

.dropdown-fields {
  padding: 10px 0;
  margin-bottom: 10px;
  margin-right: 35px;
  position: relative;
  min-height: 1px;
  float: left;
  width: 30%;
}

.date-picker {
  width: 100%;
  border-color: hsl(0, 0%, 80%) !important;
  background-color: hsl(0, 0%, 100%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  min-height: 38px;
}

.date-fields {
  padding: 10px 0;
  margin-bottom: 10px;
  margin-right: 35px;
  position: relative;
  min-height: 1px;
  float: left;
  width: 17%;
}

.code-fields {
  padding: 10px 0;
  margin-bottom: 10px;
  margin-right: 53px;
  position: relative;
  min-height: 1px;
  float: left;
  width: 45%;
}

.code-input {
  width: 100%;
  border-color: hsl(0, 0%, 80%) !important;
  background-color: hsl(0, 0%, 100%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  min-height: 38px;
}

.time-input {
  width: 100%;
  border-color: hsl(0, 0%, 80%) !important;
  background-color: hsl(0, 0%, 100%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  min-height: 30px;
}

.select-event-type {
  margin-right: 36px;
  margin-left: 36px;
  width: 100%;
}

.filter-save-button {
  border-radius: 3px;
  margin-bottom: 5px;
  color: #fff;
  background-color: #475f87;
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
}

.filter-save-button-disabled {
  background-color: #adadb4 !important;
}


.filter-cancel-button {
  border-radius: 3px;
  margin-bottom: 5px;
  margin-left: 5px;
  color: #fff;
  background-color: #919798;
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
}

.mrgn-btm10{
  margin-bottom: 10px;
}

.pdng-top{
  padding-top: 35px;
}

.react-datepicker-wrapper {
  display: block !important;
}

.required{
  color: red;
}

.error{
  border: 2px solid #f60606 !important;
}

.add-new-button {
  float: right;
}