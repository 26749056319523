@import "./v";

.top-nav {
  display: block;
  margin-left: 196px;
}

.nav_menu {
  position: static;
  float: left;
  background: #EDEDED;
  border-bottom: 1px solid #D9DEE4;
  margin-bottom: 10px;
  width: 100%;
}

.toggle {
  float: left;
  margin: 0;
  padding-top: 16px;
  width: 70px;
}

.nav {
  padding-left: 0;
  list-style: none;
}

.right-navbar {
  margin: 0;
  width: 70%;
  float: right!important;
  padding-left: 0;
  list-style: none;
}

.open {
  position: relative;
  display: inline-block;
  float: right;
}

.user-profile {
  color: #57b1b1 !important;
  font-weight: 500;
  line-height: 32px;
  margin-left: 0!important;
  padding: 13px 15px 12px;
  position: relative;
  display: block;
  text-decoration: none;
  background-color: transparent;
}

.user-profile-image {
  max-width: none;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
  border: 0;
}

.icon {
  width: 22px;
}

//OPL ADDED 
#company-section { 

  .center-left {
    justify-content: left;
    align-items: center;
  }
  .flex {
    display: flex;
  }
  .default-image {
    background: url(../assets/images/icons/default.png) no-repeat center center;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .b-r-100 {
    @include vendorPrefix("border-radius", "100%");
  }
  .of-hid {
    overflow: hidden;
  }
  .color-secondary {
    color: $colorSecondary;
  }
  p.username {
    color: #57b1b1 !important;
    font-weight: 500;
    line-height: 32px;
    margin-left: 0!important;
    padding: 13px 15px 12px;
    position: relative;
    display: block;
    text-decoration: none;
    background-color: transparent;
  }
  .user-profile{
    padding: 0px !important;
  }
}