@import "./v";

// ===================================
// Scan Flow v2 Page
// ===================================

#scanflow-v2 {
	.full-row {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		background-color: white;
		.left {
			width: 318px;
			background-color: #fafaff;
			border-right: solid 1px #eaeaea;
			.all {
				padding: 10px;
				@include calc("height", "100vh - 312px");
				overflow-y: scroll;
				.one {
					height: auto;
					width: 100%;
					@include vendorPrefix("border-radius", "6px");
					@include vendorPrefix("transition", "all 0.2s");
					border: solid 2px;
					border-color: #ebf0f4;
					padding: 16px;
					cursor: pointer;
					position: relative;
					margin-bottom: 12px;
					&:last-child {
						margin-bottom: 0;
					}
					.menu-container {
						ul {
							position: absolute;
							top: 32px;
							right: 0;
							background-color: #fff;
							border: solid 1px #a0b2c3;
							@include vendorPrefix("border-radius", "4px");
							overflow: hidden;
							width: 102px;
							li {
								list-style: none;
								border-bottom: solid 1px #f0f0f0;
								cursor: pointer;
								&:hover {
									background-color: #f0f0f0;
								}
								&:last-child {
									border-bottom: none;
								}
							}
						}
					}
				}
			}
		}
		.right {
			@include calc("width", "100% - 318px");
			.doc-container {
				@include calc("width", "100% - 318px");
				background-color: #fff;
			}
			.from-container {
				width: 318px;
				background-color: #fafaff;
				border-left: solid 1px #eaeaea;
				overflow: hidden;
			}
		}
	}
}

// ===================================
// Login v2 Page
// ===================================

#login-v2 {
	background-color: white !important;
	height: 100vh;
	video.bg-video {
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 2;
		position: absolute;
		-o-object-fit: cover;
		object-fit: cover;
		-o-object-position: top center;
		object-position: top center;
		vertical-align: middle;
	}
	.form {
		width: 348px;
		background-color: white;
		border: solid 1px #f0f0f0;
		padding: 20px 28px 28px 28px;
		@include vendorPrefix("border-radius", "8px");
		@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.12)");
		position: relative;
		z-index: 3;
		img.logo {
			height: 92px;
			width: auto;
			display: block;
			margin: 0 auto;
		}
		hr {
			height: 80px;
			width: 2px;
			background-color: #f0f0f0;
			margin: 20px auto;
		}
	}
}

// ===================================
// Activities v2 Page
// ===================================

#activity-v2 {
	.activity-cards {
		padding: 0 15px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(auto-fill, auto);
		grid-row-gap: 16px;
		grid-column-gap: 16px;
		overflow: hidden;
		.activity-card {
			height: auto;
			width: 100%;
			@include vendorPrefix("border-radius", "6px");
			@include vendorPrefix("transition", "all 0.2s");
			border: solid 2px;
			background-color: white;
			border-color: #ebf0f4;
			padding: 14px 18px 20px 18px;
			position: relative;
			&:hover {
				border-color: #e6f2fc;
			}
			.menu-container {
				ul {
					position: absolute;
					top: 32px;
					right: 0;
					background-color: #fff;
					border: solid 1px #a0b2c3;
					@include vendorPrefix("border-radius", "4px");
					overflow: hidden;
					width: 102px;
					li {
						list-style: none;
						border-bottom: solid 1px #f0f0f0;
						cursor: pointer;
						&:hover {
							background-color: #f0f0f0;
						}
						&:last-child {
							border-bottom: none;
						}
					}
				}
			}
		}
	}
}

#activity-detail-box {
	width: 586px;
	padding: 30px;
	@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.13)");
}

// ===================================
// Add Activity v2 Page
// ===================================

#add-activity {
	background-color: white;
	.form-holder {
		max-width: 720px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 60px;
		.inner { 
			overflow: visible !important;
			border: solid 1px #f0f0f0;
			padding: 40px;
			@include vendorPrefix("border-radius", "8px");
			overflow: hidden;
			background-color: white;
			@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.13)");
			table {
				width: 100%;
				display: table;
				border-collapse: collapse;
				tbody {
					tr {
						td {
							vertical-align: top;
							padding-bottom: 8px;
							padding-right: 4px;
							&:nth-child(2n) {
								padding-left: 4px;
							}
						}
						td[colSpan="2"] {
							padding-right: 0;
						}
					}
				}
			}
			.inner-data {
				table {
					width: 100%;
					border-collapse: collapse;
					font-size: 0.875rem;
					border: 1px solid #ddd;
					border-radius: 8px;
					th,
					td {
						padding: 8px;
						text-align: left;
						border-bottom: 1px solid #ddd;
						color: $colorPrimary;
						.badge {
							display: inline-block;
							padding: 4px 8px;
							border: 1px solid #ddd;
							border-radius: 4px;
							color: $colorPrimary;
						}
					}

					th.w-100 {
						width: 100px;
					}

					th.w-60 {
						width: 60px;
					}

					/* Alternate row background */
					// tr:nth-child(even) {
					//   background-color: rgba(0, 0, 0, 0.05);
					// }
				}
			}
		}
	}
}

// ===================================
// =============
// ===================================

// ===================================
// Add Patient v2 Page
// ===================================

#add-patient {
	background-color: white;
	.form-holder {
		max-width: 900px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 60px;
		.inner {
			border: solid 1px #f0f0f0;
			padding: 40px;
			@include vendorPrefix("border-radius", "8px");
			overflow: hidden;
			background-color: white;
			@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.13)");
			table {
				width: 100%;
				display: table;
				border-collapse: collapse;
				tbody {
					tr {
						td {
							vertical-align: top;
							padding-bottom: 8px;
							padding-right: 4px;
							&:nth-child(2n) {
								padding-left: 4px;
							}
						}
						td[colSpan="2"] {
							padding-right: 0;
						}
					}
				}
			}
		}
	}
}

// ===================================
// =============
// ===================================

// ===================================
// Insurance v2 Page
// ===================================

#insurance {
}

// ===================================
// =============
// ===================================

// ===================================
// Add Insurance v2 Page
// ===================================

#add-insurance {
	background-color: white;

	.form-holder {
		max-width: 720px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 60px;

		.inner {
			border: solid 1px #f0f0f0;
			padding: 40px;
			@include vendorPrefix("border-radius", "8px");
			overflow: hidden;
			background-color: white;
			@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.13)");

			table {
				width: 100%;
				display: table;
				border-collapse: collapse;

				tbody {
					tr {
						td {
							vertical-align: top;
							padding-bottom: 8px;
							padding-right: 4px;

							&:nth-child(2n) {
								padding-left: 4px;
							}
						}

						td[colSpan="2"] {
							padding-right: 0;
						}
					}
				}
			}
			.contect-date {
				table {
					width: 100%;
					border-collapse: collapse;
					font-size: 0.875rem;
					border: 1px solid #ddd;
					border-radius: 8px;

					th,
					td {
						padding: 8px;
						text-align: left;
						border-bottom: 1px solid #ddd;
						color: $colorPrimary;
						.badge {
							display: inline-block;
							padding: 4px 8px;
							border: 1px solid #ddd;
							border-radius: 4px;
							color: $colorPrimary;
						}
					}

					th.w-100 {
						width: 100px;
					}

					th.w-60 {
						width: 60px;
					}

					/* Alternate row background */
					// tr:nth-child(even) {
					//   background-color: rgba(0, 0, 0, 0.05);
					// }
				}
			}
		}
	}
}

// ===================================
// =============
// ===================================

// ===================================
// Insurance v2 Page
// ===================================

#insurance {
}

// ===================================
// =============
// ===================================

// ===================================
// Add Insurance v2 Page
// ===================================

#add-insurance {
	background-color: white;
	.form-holder {
		max-width: 720px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 60px;
		.inner {
			border: solid 1px #f0f0f0;
			padding: 40px;
			@include vendorPrefix("border-radius", "8px");
			overflow: hidden;
			background-color: white;
			@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.13)");
			table {
				width: 100%;
				display: table;
				border-collapse: collapse;
				tbody {
					tr {
						td {
							vertical-align: top;
							padding-bottom: 8px;
							padding-right: 4px;

							&:nth-child(2n) {
								padding-left: 4px;
							}
						}

						td[colSpan="2"] {
							padding-right: 0;
						}
					}
				}
			}

			.contect-date {
				table {
					width: 100%;
					border-collapse: collapse;
					font-size: 0.875rem;
					border: 1px solid #ddd;
					border-radius: 8px;

					th,
					td {
						padding: 8px;
						text-align: left;
						border-bottom: 1px solid #ddd;
						color: $colorPrimary !important;

						.badge {
							display: inline-block;
							padding: 4px 8px;
							border: 1px solid #ddd;
							border-radius: 4px;
							color: $colorPrimary !important;
						}
					}

					th.w-100 {
						width: 100px;
					}

					th.w-60 {
						width: 60px;
					}
				}
			}
		}
	}
}

// ===================================
// Add Physician Assistant v2 Page
// ===================================

#add-physician-assistant {
	background-color: white;
	.form-holder {
		max-width: 964px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 60px;
		.inner { 
			overflow: visible !important;
			border: solid 1px #f0f0f0;
			padding: 40px;
			@include vendorPrefix("border-radius", "8px");
			overflow: hidden;
			background-color: white;
			@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.13)");
			table {
				width: 100%;
				display: table;
				border-collapse: collapse;
				tbody {
					tr {
						td {
							vertical-align: top;
							padding-bottom: 8px;
							padding-right: 4px;
							&:nth-child(2n) {
								padding-left: 4px;
							}
						}
						td[colSpan="2"] {
							padding-right: 0;
						}
					}
				}
			}
			.inner-table-date {
				table {
					width: 100%;
					border-collapse: collapse;
					font-size: 0.875rem;
					border: 1px solid #ddd;
					border-radius: 8px;

					th {
						padding: 8px;
						color: $colorPrimary !important;
					}

					td {
						padding: 8px;
						text-align: left;
						border-bottom: 1px solid #ddd;
						color: $colorPrimary !important;
						.badge {
							display: inline-block;
							padding: 4px 8px;
							border: 1px solid #ddd;
							border-radius: 4px;
							color: $colorPrimary !important;
						}
					}

					th.w-100 {
						width: 100px;
					}

					th.w-60 {
						width: 60px;
					}

					/* Alternate row background */
					// tr:nth-child(even) {
					//   background-color: rgba(0, 0, 0, 0.05);
					// }
				}
			}
		}
	}
}
 
// ====== Profile Page ====== //

#profile-page {
	padding: 20px;
	.cover-image {
		height: 248px;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		padding: 20px 40px;
	}
	.user-info {
		padding: 0 40px 20px 40px;
		display: flex;
		align-items: flex-end;
		border-bottom: solid 1px #f3f4f6;
		gap: 20px;
		margin-top: -40px;
		.user-picture {
			height: 132px;
			width: 132px;
			border: solid 4px #fafafa;
			.add-picture {
				position: absolute;
				bottom: 0;
				right: 0;
				height: 40px;
				width: 40px;
				background-color: #fafafb;
				svg {
					height: 20px;
					width: auto;
					display: block;
				}
			}
		}
	}
	.about-content {
		background-color: #fafafb;
		padding: 20px;
		display: flex;
		.left {
			width: 268px;
			hr {
				outline: none;
				border: none;
				height: 1px;
				width: 100%;
				margin: 10px 0 10px 0;
				background-color: #f3f4f6;
			}
			ul {
				li {
					list-style: none;
					padding: 15px 15px;
					cursor: pointer;
					&:hover {
						background-color: #f0f0f0;
					}
					margin-bottom: 10px;
				}
				li.active {
					background-color: #e0eeeb;
					color: $colorPrimary;
					font-weight: 700;
				}
			}
		}
		.right {
			@include calc("width", "100% - 268px");
			padding-left: 40px;
		}
	}
}
// ============= 

// ===================================
// ====== Scheduling =======
// ===================================

#opl-scheduling {
	.rbc-calendar {
		font-family: "Inter", sans-serif !important;
	}
	.rbc-toolbar {
		margin-bottom: 12px;
		.rbc-btn-group {
			display: flex;
			gap: 4px;
			button {
				background-color: #f0f0f0;
				color: $colorPrimary !important;
			}
			button.rbc-active {
				background-color: $colorPrimary;
				color: $colorSecondary !important;
			}
		}
		.rbc-toolbar-label {
			color: $colorPrimary !important;
		}
	}
	.rbc-header {
		a {
			color: $colorPrimary !important;
		}
	}
	.rbc-timeslot-group {
		.rbc-label {
			padding: 12px;
		}
	}
	.rbc-row-segment {
		padding: 2px 0;
	}
	.rbc-event-single {
		padding: 2px 5px;
		@include vendorPrefix("border-radius", "5px");
		padding: 2px 5px;
		text-align: left;
		cursor: pointer;
		width: 100%;
		background-color: $colorPrimary;
		color: #92f5e3;
		font-size: 11px;
	}
	.rbc-event-single-new-event,
	.rbc-event-single-started-event,
	.rbc-event-single-cancelled-event,
	.rbc-event-single-completed-event {
		color: white;
	}
	.rbc-event-single-new-event {
		background-color: #60a6d3;
	}
	.rbc-event-single-started-event {
		background-color: #9c5194;
	}
	.rbc-event-single-cancelled-event {
		background-color: #26a797;
	}
	.rbc-event-single-completed-event {
		background-color: #b4b14e;
	}
	.rbc-date-cell,
	.rbc-date-cell a {
		color: $colorPrimary !important;
	}
	.rbc-today {
		background-color: beige !important;
	}
}

// ===================================
// =============
// =================================== 
//============== Steper ========
.config-application-stepper {
	padding: 20px 0;
	.step {
		width: 155px;
		position: relative;
		&:after {
			position: absolute;
			width: 100%;
			content: "";
			height: 4px;
			background: #cfcfcf;
			bottom: 10px;
			left: 50%;
			margin-left: 12px;
		}
		&:last-child {
			&::after {
				display: none;
			}
		}
	}
	.icon {
		height: 24px;
		width: 24px;
		display: block;
		margin: 0 auto !important;
		svg {
			height: 24px;
			width: 24px;
		}
	}
	.incomplete {
		svg {
			path {
				fill: #cfcfcf;
			}
		}
	}
	.complete {
		svg {
			path {
				fill: $colorPrimary !important;
			}
		}
		&:after {
			background: $colorPrimary !important;
		}
	}
}
  
